<template>
    <div class="exam-view">
        <!-- <div class="navBox">
            <div v-for="item in sections" :key="item._id.$id">
                {{item.section_title}}
                <div style="display:flex;flex-wrap:wrap">
                    <div v-for="(x,index) in item.questions_item" :key="index" class="questionNavItem" @click="toQuestion(item.section_title,index)">{{index+1}}</div>
                </div>
            </div>
        </div> -->
        <div class="topBanner">
            <img src="../../../assets/topBanner.png" class="topImg"/>
        </div>
        <div class="line"></div>

        <div class="main">
            <!-- <img class="mainBack" src="../../../assets/homeBack.png"> -->
            <div class="content">
                <div class="hintInfo">
                    <div class="item">
                        <div>
                        <div>{{countDownTimes}}</div>
                        <div>Unusual Actions：{{visibleChangeCount}}</div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="content_list2" v-for="item in sections" :key="item._id.$id">
                        <div class="content_list2_title">
                            <div class="section_title">{{item.section_title}}</div>
                            <div class="section_intro">{{item.section_instruction}}</div>
                            <div class="section_material" v-if="item.section_material_type==='text'">
                                <div v-html="item.section_material"></div>
                            </div>
                            <div class="section_material" v-if="item.section_material_type==='audio'">
                                <audio :id="'audio_'+item._id.$id" :src="item.clean_material"></audio>
                                <img src="../../../assets/play.png" class="playbtn" @click="play(item._id.$id)">
                            </div>
                        </div>
                        <exam :questions="item.questions_item" :section="item.section_title" @submitAnswer="saveAnswer"></exam>
                    </div>
                    <div @click="finishTest" class="submitBtn" v-if="isPublished">Submit </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import exam from './exam'
import * as QNRTC from 'pili-rtc-web'
import { getExerciseTemplateByStudent, uploadAnswers, changeVisibleLog, getTestVisibleChangeCount, submitAnswer,createRoomToken,saveAudioRecord,uploadTimeStamp,getTimeStamp} from '../../../api/index'
import { getUserId ,getProjectCode} from '../../../utils/store'

const myRoom = new QNRTC.TrackModeSession()

export default {
    components: {
        exam
    },
    data () {
        return {
            id:'',
            user_id:getUserId(),
            countDownTimes: '',
            questionList: [],
            answerList: [],
            test_name: "",
            test_instruction: "",
            countDownThread: undefined,
            sections:[],
            visibleChangeCount: 0,
            publishedTracks:[],
            publishedTrackInfos:[],
            roomId:this.$route.query.roomId,
            isPlay:false,
            currentAudio:'',
            isPublished:false,
            clock:'',
            remainStamp:''
        };
    },
    beforeDestroy () {
        if (this.countDownThread) {
            window.clearInterval(this.countDownThread)
        }
    },
    destroyed(){
        myRoom.leaveRoom();
        for (const track of this.publishedTracks) {
            track.release();
        }
        window.clearInterval(this.clock)
    },
    async mounted () {
        document.title = '考试'
        await this.leaveRoom()
        this.$confirm('Do you agree to share your screen?', 'Please confirm', {
            confirmButtonText: 'confirm',
            cancelButtonText: 'cancel',
            type: 'warning'
        }).then(async ()=>{
            await this.joinRoom()
            if(this.isPublished){
                if(this.$route.query.template_id){
                    this.id = this.$route.query.template_id
                    this.getTestVisibleChangeCount()
                }
                document.addEventListener('visibilitychange', ()=>{
                    document.hidden && this.handleVisiable()
                })
                this.fetchData()
                // this.checkInRoom()
            }
        })
    },
    methods: {
        async joinRoom () {
            let roomToken
            const result = await this.createRoomToken()
            if(result.code==0){
                roomToken = result.data.token
            }else{
                this.$message(result.msg)
                return;
            }
            try {
                // 加入房间
                await myRoom.joinRoomWithToken(roomToken)
                await this.publishScreen()
               
            } catch (e) {
                console.error(e)
                this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
                return
            }


        },
        async publishScreen () {
            for (const track of this.publishedTracks) {
                await track.release();
            }
            let tracks
            try {
                tracks = await QNRTC.deviceManager.getLocalTracks({
                    screen: {
                        enabled: true,
                        source: "window",
                        bitrate: 600,
                        height: 480,
                        width: 640
                    }
                })

                try {
                    // 发布采集流
                   
                    await myRoom.publish(tracks)
                    
                    let trackInfoList = tracks.map(item=>item.info)
                    this.publishedTrackInfos = [...this.publishedTrackInfos,...trackInfoList];
                    this.publishedTracks = [...this.publishedTracks,...tracks];
                    this.$message({
                        message:'share success',
                        type:'success'
                    })
                    this.isPublished = true
                } catch (e) {
                    console.error(e)
                    this.$message(`发布失败，ErrorCode: ${e.code}`)
                }
                
            } catch (e) {
                console.error(e)
                this.$message(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
                return 
            }
        },
        createRoomToken () {
            return new Promise((resolve,reject)=>{
                createRoomToken(this.roomId,getUserId(),'user').then(res => {
                    resolve(res.data)
                })
            })
        },
        handleVisiable (e) {
            this.visibleChangeCount++
            changeVisibleLog(this.user_id, this.id)
        },
        async fetchData () {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await this.getExerciseTemplateByStudent()
            this.saveTimeStamp()
            loading.close();
        },
        async getExerciseTemplateByStudent(){
            return new Promise((resolve)=>{
                getExerciseTemplateByStudent(this.id,getUserId()).then(res => {
                    console.log(res)
                    if (res.data.code === 0) {
                        this.test_name = res.data.data.template_name;
                        this.test_instruction = res.data.data.template_instruction;
                        this.sections = res.data.data.sections_item
                        this.startCountDown(res.data.data.test_duration)
                        for(let item of this.sections){
                            this.questionList = [...this.questionList,...item.questions_item];
                        }
                        this.questionList.map(item=>{
                            if(!item.choice){
                                item.choice = '未作答';
                            }
                        })
                        resolve(true)
                    }
                })
            })
        },
        async getTestVisibleChangeCount(){
            return new Promise((resolve)=>{
                getTestVisibleChangeCount(this.id, this.user_id).then(res => {
                    this.visibleChangeCount = res.data.data
                    resolve(true)
                })
            })
        },
        startCountDown (minute) {
            let totalTime
            getTimeStamp(getUserId(),this.id).then(res=>{
                if(res.data.code==0){
                    totalTime = res.data.data.stamp
                }else{
                    totalTime = minute * 60
                }
                this.countDownThread = window.setInterval(() => {
                    this.countDownTimes = 'Remaining Time：'+ this.formatSeconds(totalTime)
                    this.remainStamp = totalTime
                    totalTime--
                    if (totalTime < 0) {
                    window.clearInterval(this.countDownThread)
                    this.$alert('Time out!', 'Tips', {
                        confirmButtonText: 'confirm',
                        callback: action => {
                            this.uploadAnswers()
                        }
                    });
                }
                }, 1000)

            })
        },
        saveAnswer (e) {
            let question_id = e.id
            let choice = e.choice
            submitAnswer(question_id,this.id,choice,this.user_id,getProjectCode());
            let question = this.questionList.find(item=>item._id.$id===e.id);
            question.choice = e.choice
        },
        uploadAnswers () {
            let answerList = this.questionList.map(item=>item.choice);
            uploadAnswers(answerList, this.user_id, this.id).then(res => {
                if(this.currentAudio){
                    this.currentAudio.pause()
                }
                if (res.data.code === 0) {
                    this.$message({
                        message: 'Upload Successful',
                        type: 'success'
                    })
                    this.$router.push('/home')
                }else{
                    this.$message('You have finished the exam');
                }
            })
        },
        formatSeconds (endTime) {
            let secondTime = parseInt(endTime)
            let min = 0
            let h = 0
            let result = ''
            if(secondTime>60){
                min=parseInt(secondTime/60)
                secondTime=parseInt(secondTime%60)
                if(min>60){
                    h= parseInt(min / 60)
                    min= parseInt(min%60)
                }
            }
            result =`${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
            return result
        },
        finishTest(){
            window.clearInterval(this.countDownThread)
            this.$confirm('Are you sure you want to submit？', 'Please Confirm', {
                type: 'warning',
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
            }).then(() => {
                this.uploadAnswers()
            })
        },
        toQuestion(title,index){
            document.getElementById('a'+title+index).scrollIntoView();
        },
        async leaveRoom(){
            await myRoom.leaveRoom();
            for (const track of this.publishedTracks) {
                await track.release();
            }
        },
        play(id){
            if(this.isPlay){
                this.$message.warning('You are playing the audio!')
                return 
            }
            let auto = document.getElementById('audio_'+id);
            saveAudioRecord(getUserId(),id,this.id).then(res=>{
                if(res.data.code===0){
                    auto.play();
                    this.currentAudio = auto
                    this.isPlay = true
                    auto.onended = () => {
                        this.isPlay = false
                    };
                }else{
                    this.$message.warning('You have played the audio!')
                }
            })
        },
        checkInRoom(){
            this.clock=window.setInterval(() => {
                let tracks = myRoom.publishedTracks
                if(tracks.length===0){
                    this.$alert('未检测到屏幕分享，自动交卷!', 'Tips', {
                        confirmButtonText: 'confirm',
                        callback: action => {
                            this.uploadAnswers()
                        }
                    });
                }
            },30000)
        },
        saveTimeStamp(){
            this.clock=window.setInterval(() => {
                uploadTimeStamp(getUserId(),this.id,this.remainStamp)
            },30000)
        }
    }
}
</script>

<style lang="less">
.exam-view {
    width: 100%;
    .topBanner{
        background: rgb(253,166,0);
        height: 18vh;
        text-align: center;
        .topImg{
            width: auto;
            height: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .line{
        width: 100%;
        height: 2vh;
        background: rgb(14,33,68);
    }
    .main{
        display: flex;
        flex-direction: column;
        height: 80vh;
        position: relative;
        .mainBack{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -2;
        }
        .content {
            width: 66%;
            margin: 0 auto;
            height: 100%;
            // overflow-y: scroll;
            background: white;
            padding: 0 40px;
            .container{
                height: 88%;
                margin-top: 1vh;
                overflow-y: scroll;
                .content_list2{
                margin-top: 40px;
                    .section_title{
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: rgb(253,166,0);
                    }
                    .section_intro{
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: rgb(253,166,0);
                        margin-top:20px
                    }
                    .section_material{
                        font-family: Microsoft YaHei;
                        margin-top:20px;
                        .playbtn{
                            width:34px;
                            display: block;
                            cursor: pointer;
                        }
                    }
                }
                .submitBtn{
                    width: 200px;
                    height: 5vh;
                    line-height: 5vh;
                    text-align: center;
                    margin:100px auto;
                    background: #E74646;
                    border-radius: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    font-size: 2vh;
                    cursor: pointer;   
                }
            }
        }

        .hintInfo{
            display: flex;
            justify-content: flex-end;
            height: 7%;
            margin-top:1vh;
            .item{
                padding: 6px 14px;
                background: rgb(253,166,0);
                border-radius: 4px;
                font-size: 1.4vh;
                font-weight: 600;
                color: white;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
    }
}

.navBox{
    position: fixed;
    z-index: 1000
}
.questionNavItem{
    width: 24px;
    height: 24px;
    background: #CC5E63;
    border-radius: 50%;
    line-height: 24px;
    color: white;
    cursor: pointer;
    text-align: center;
    margin-right:2px;
}
</style>
